import React from 'react'

import Page from '../templates/Page'

const Impressum = () => (
  <Page title="Impressum">
    <div style={{ marginBottom: '200px' }}>
      <h1>
        <strong>
          Kontakt:
          <br />
        </strong>
      </h1>
      <p>
        Für Informationen können Sie uns jederzeit gerne eine Email schreiben
        oder uns anrufen.
      </p>
      <table height="81">
        <tbody>
          <tr>
            <td>E-Mail:</td>
            <td>josee.boucher@kleine-eismanufaktur.de</td>
          </tr>
          <tr>
            <td>Telefon:</td>
            <td>0151 43101426</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Page>
)

export default Impressum
